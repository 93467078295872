$(document).ready(function() {
  var $body = $("body");
  var $window = $(window);

  /********** WORK GRID **********/
  var $grid = $(".grid");

  if ($grid.length) {
    $grid.isotope({
      masonry: {
        columnWidth: ".grid-sizer",
        gutter: 32,
      },
      percentPosition: false,
      itemSelector: ".grid-item",
    });
    $grid.imagesLoaded().progress(function() {
      $grid.isotope();
    });

    $(".filter-item").on("click", function(e) {
      e.preventDefault();
      $(".filter-item.active").removeClass("active");
      $(this).addClass("active");
      var f = $(this).data("f");
      $grid.isotope({ filter: f });
    });

    var triggerInifinite = false;

    function infinite() {
      // var bottomScreen = $(document).scrollTop() + $(window).height();
      // var bottomGrid = $grid.scrollTop() + $grid.height();
      // if (bottomScreen - 100 >= bottomGrid && !triggerInifinite) {
      //   $(document).off("scroll", infinite);
      //   triggerInifinite = true;
      //   load_ajax_posts();
      // }
    }
    if (!is_touch_device()) {
      $(document).on("scroll", infinite);
    }

    var $loader = $(".load-more-button").find("a");
    var offset = 9;
    var ppp = 6;

    $loader.on("click", function(e) {
      e.preventDefault();
      load_ajax_posts();
    });

    function load_ajax_posts() {
      if (
        !$loader.hasClass("post_loading_loader") &&
        !$loader.hasClass("post_no_more_posts")
      ) {
        $.ajax({
          type: "POST",
          dataType: "html",
          url: ajax_posts.ajaxurl,
          data: {
            ppp: ppp,
            offset: offset,
            action: "ztn_more_post_ajax",
          },
          beforeSend: function() {
            $loader.addClass("post_loading_loader").html("Laden...");
          },
          success: function(data) {
            var $data = $(data);
            if ($data.length) {
              $grid.append($data);
              $grid.isotope("appended", $data);
              $grid.imagesLoaded().progress(function() {
                $grid.isotope("layout");
                $loader.removeClass("post_loading_loader").html("Laad meer");
              });
              triggerInifinite = false;
              if (!is_touch_device()) {
                $(document).on("scroll", infinite);
              }
            } else {
              $loader
                .removeClass("post_loading_loader")
                .addClass("post_no_more_posts")
                .html("Alles ingeladen");
            }
          },
          error: function(jqXHR, textStatus, errorThrown) {
            $loader.html(
              $.parseJSON(jqXHR.responseText) +
                " :: " +
                textStatus +
                " :: " +
                errorThrown
            );
            console.log(jqXHR);
          },
        });
        offset += ppp;
        return false;
      }
    }
  }

  /********** INTRO BLOCK **********/
  var $introBlock = $(".intro-block");
  var prevented = false;

  if ($introBlock.length) {
    function removeIntroBlock() {
      $("html,body").scrollTop(0);
      $introBlock.addClass("remove");
      $body.removeClass("overflow");
      $grid.isotope();
      $introBlock.fadeOut(800, function() {
        $(document).off("wheel", preventScrolling);
      });
    }

    function preventScrolling(e) {
      e.preventDefault();
      var delta = e.originalEvent.deltaY;
      if (delta > 0 && !prevented) {
        prevented = true;
        removeIntroBlock();
      }
    }

    $(".intro-block__read-more").on("click", function() {
      removeIntroBlock();
    });

    $body.addClass("overflow");
    $(document).on("wheel", preventScrolling);
    $introBlock.on("click touchend", removeIntroBlock);
  }

  /********** MENU **********/
  var $header = $(".header");
  var $menuWrapper = $(".menu-wrapper");
  var $fancyShow = $(".fancy-show");
  var fancyIndex = 0;
  var fancyLength = $fancyShow.length;

  function fancyShow(timer) {
    $fancyShow.eq(fancyIndex).addClass("active");
    fancyIndex++;
    if (fancyIndex >= fancyLength) {
      clearInterval(timer);
    }
  }

  $(".menu-button").on("click", function() {
    $header.toggleClass("open");
    if ($header.hasClass("open")) {
      $menuWrapper.addClass("open");
      fancyIndex = 0;
      setTimeout(function() {
        var fancyTimer = setInterval(function() {
          fancyShow(fancyTimer);
        }, 150);
      }, 200);
    } else {
      $menuWrapper.removeClass("open");
      $fancyShow.removeClass("active");
    }
  });

  /********** GALLERY **********/
  var selectorG = 'a[data-imagelightbox="g"]';

  if ($(selectorG).length) {
    function overlayOn() {
      $(".imagelightbox-overlay").addClass("active");
      $body.addClass("overlay-active");
    }
    function overlayOff() {
      $(".imagelightbox-overlay").removeClass("active");
      $body.removeClass("overlay-active");
    }
    function activityIndicatorOn() {
      $(".imagelightbox-overlay").append(
        "<div class='imagelightbox-loading'></div>"
      );
    }
    function activityIndicatorOff() {
      $(".imagelightbox-loading").remove();
    }

    var arrowsOn = function(instance, selector) {
      var $arrows = $(
        '<button type="button" class="imagelightbox-arrow imagelightbox-arrow-left"></button><button type="button" class="imagelightbox-arrow imagelightbox-arrow-right"></button>'
      );

      $arrows.appendTo("body");

      $arrows.on("click touchend", function(e) {
        e.preventDefault();

        var $this = $(this),
          $target = $(
            selector + '[href="' + $("#imagelightbox").attr("src") + '"]'
          ),
          index = $target.index(selector);

        if ($this.hasClass("imagelightbox-arrow-left")) {
          index = index - 1;
          if (!$(selector).eq(index).length) {
            index = $(selector).length;
          }
        } else {
          index = index + 1;
          if (!$(selector).eq(index).length) {
            index = 0;
          }
        }
        instance.switchImageLightbox(index);
        return false;
      });
    };

    var arrowsOff = function() {
      $(".imagelightbox-arrow").remove();
    };
    if (!is_touch_device()) {
      var instanceG = $(selectorG).imageLightbox({
        onStart: function() {
          arrowsOn(instanceG, selectorG);
          overlayOn();
        },
        onEnd: function() {
          arrowsOff();
          activityIndicatorOff();
          overlayOff();
        },
        onLoadStart: function() {
          activityIndicatorOn();
        },
        onLoadEnd: function() {
          $(".imagelightbox-arrow").css("display", "block");
          activityIndicatorOff();
        },
      });
    } else {
      $(".galerij__item")
        .find("a")
        .on("click", function(e) {
          e.preventDefault();
        });
    }
  }

  /********** BACK BUTTON SINGLE **********/
  var $projectBack = $(".project-back");
  var $document = $(document);

  if ($projectBack.length && $window.width() > 767) {
    function backButton() {
      if (
        $window.scrollTop() > $window.height() &&
        $window.scrollTop() + $window.height() + 400 <= $document.height()
      ) {
        $projectBack.addClass("active");
      } else {
        $projectBack.removeClass("active");
      }
    }
    backButton();
    $window.scroll(backButton);
  }

  /********** DETECT TOUCH DEVICES **********/
  function is_touch_device() {
    return (
      "ontouchstart" in window || navigator.maxTouchPoints // works on most browsers
    ); // works on IE10/11 and Surface
  }

  if (is_touch_device()) {
    $body.addClass("touch-device");
  } else {
    $body.addClass("no-touch-device");
  }

  /********** SHARE BUTTONS **********/
  if ($(".social-wall-item__sharing").length) {
    var windowWidth = 600;
    var windowHeight = 400;

    function getSocialData(item) {
      var $socialItem = item.closest(".social-wall-item");
      var socialData = {
        link: $socialItem
          .find(".social-wall-item__image")
          .find("a")
          .attr("href"),
        image: $socialItem
          .find(".social-wall-item__image")
          .find("img")
          .attr("src"),
      };
      return socialData;
    }

    $(".icon--facebook").on("click", function(e) {
      e.preventDefault();
      socialData = getSocialData($(this));
      window.open(
        "https://www.facebook.com/sharer.php?u=" +
          socialData["link"] +
          "&picture=" +
          socialData["image"],
        "newwindow",
        "width=" + windowWidth + ", height=" + windowHeight
      );
    });

    $(".icon--twitter").on("click", function(e) {
      e.preventDefault();
      socialData = getSocialData($(this));
      window.open(
        "http://twitter.com/share?url=" + socialData["link"],
        "newwindow",
        "width=" + windowWidth + ", height=" + windowHeight
      );
    });

    $(".icon--pinterest").on("click", function(e) {
      e.preventDefault();
      socialData = getSocialData($(this));
      window.open(
        "https://pinterest.com/pin/create/button/?url=" +
          socialData["link"] +
          "&media=" +
          socialData["image"],
        "newwindow",
        "width=" + windowWidth + ", height=" + windowHeight
      );
    });

    $(".icon--linkedin").on("click", function(e) {
      e.preventDefault();
      socialData = getSocialData($(this));
      window.open(
        "http://www.linkedin.com/shareArticle?mini=true&url=" +
          socialData["link"],
        "newwindow",
        "width=" + windowWidth + ", height=" + windowHeight
      );
    });

    $(".icon--googleplus").on("click", function(e) {
      e.preventDefault();
      socialData = getSocialData($(this));
      window.open(
        "https://plus.google.com/share?url=" + socialData["link"],
        "newwindow",
        "width=" + windowWidth + ", height=" + windowHeight
      );
    });
  }

  /********** ANIMATIONS **********/
  var $animateElement = $(".animate-element");

  if ($animateElement.length) {
    $animateElement.each(function() {
      var tlAnimate = new TimelineMax();
      tlAnimate
        .set(this, { autoAlpha: 0, y: 30 })
        .to(this, 0.8, { autoAlpha: 1, y: 0 });
      var animateController = new ScrollMagic.Controller();
      var AnimateScene = new ScrollMagic.Scene({
        triggerElement: this,
        reverse: false,
      })
        .triggerHook("onEnter")
        .addTo(animateController)
        .setTween(tlAnimate);
    });
  }
});
